@font-face {
	font-family: 'simple-calendar';
	src: url('../fonts/simple-calendar.eot?43976014');
	src:
		url('../fonts/simple-calendar.eot?43976014#iefix') format('embedded-opentype'),
		url('../fonts/simple-calendar.woff?43976014') format('woff'),
		url('../fonts/simple-calendar.ttf?43976014') format('truetype'),
		url('../fonts/simple-calendar.svg?43976014#simple-calendar') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='simcal-icon-']:before,
[class*=' simcal-icon-']:before {
	display: inline-block;
	font-family: 'simple-calendar';
	font-style: normal;
	font-weight: normal;
	margin-right: 0.2em;
	speak: none;
	text-decoration: inherit;
	text-align: center;
	width: 1em;
	font-variant: normal;
	text-transform: none;
	margin-left: 0.2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.simcal-icon-spin {
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	display: inline-block;
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-webkit-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-o-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-ms-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.simcal-icon-animate:before {
	transition: all 0.2s ease-in-out;
}

.simcal-icon-rotate-180:before {
	transform: rotate(180deg);
}

.simcal-icon-calendar-empty:before {
	content: '\e800';
}

.simcal-icon-calendar:before {
	content: '\e801';
}

.simcal-icon-calendar-logo:before {
	content: '\e802';
}

.simcal-icon-settings:before {
	content: '\e804';
}

.simcal-icon-toggles:before {
	content: '\e805';
}

.simcal-icon-list:before {
	content: '\e806';
}

.simcal-icon-event:before {
	content: '\e807';
}

.simcal-icon-help:before {
	content: '\e808';
}

.simcal-icon-panel:before {
	content: '\e80a';
}

.simcal-icon-grid:before {
	content: '\e80b';
}

.simcal-icon-google:before {
	content: '\e80c';
}

.simcal-icon-docs:before {
	content: '\e80f';
}

.simcal-icon-hourglass:before {
	content: '\e811';
}

.simcal-icon-globe:before {
	content: '\e812';
}

.simcal-icon-timezones:before {
	content: '\e813';
}

.simcal-icon-warning:before {
	content: '\e815';
}

.simcal-icon-wordpress:before {
	content: '\e814';
}

.simcal-icon-up:before {
	content: '\e80e';
}

.simcal-icon-right:before {
	content: '\e809';
}

.simcal-icon-down:before {
	content: '\e80d';
}

.simcal-icon-left:before {
	content: '\e803';
}

.simcal-icon-spinner:before {
	content: '\e810';
}

.simcal-calendar {
	position: relative;
}

.simcal-powered {
	display: block;
	margin: -10px 0 20px;
}

.simcal-align-left {
	text-align: left;
}

.simcal-align-right {
	text-align: right;
}

.simcal-default-calendar .simcal-current h3 {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar .simcal-nav {
	vertical-align: middle;
}

.simcal-default-calendar .simcal-nav-button {
	background: transparent;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	margin: 0;
	outline: none;
	padding: 0;
	transition: margin 0.2s ease-out;
	width: 100%;
}
.simcal-default-calendar .simcal-nav-button:focus,
.simcal-default-calendar .simcal-nav-button:hover {
	background: transparent;
	border: 0;
	box-shadow: none;
	outline: none;
}
.simcal-default-calendar .simcal-nav-button.simcal-prev:hover {
	margin-left: -10px;
}
.simcal-default-calendar .simcal-nav-button.simcal-next:hover {
	margin-right: -10px;
}
.simcal-default-calendar .simcal-nav-button:disabled {
	cursor: default;
}
.simcal-default-calendar .simcal-nav-button:disabled:hover {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar .simcal-events {
	list-style: none;
	margin-left: 0;
}

.simcal-default-calendar .simcal-events-toggle {
	border: 0;
	box-shadow: none;
	text-align: center;
	width: 100%;
}
.simcal-default-calendar .simcal-events-toggle:active,
.simcal-default-calendar .simcal-events-toggle:focus,
.simcal-default-calendar .simcal-events-toggle:hover {
	border: 0;
	box-shadow: none;
	outline: none;
}

.simcal-default-calendar ul.simcal-attachments,
.simcal-default-calendar ul.simcal-attendees {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar li.simcal-attachment,
.simcal-default-calendar li.simcal-attendee {
	list-style: none;
	margin-bottom: 4px;
}
.simcal-default-calendar li.simcal-attachment:last-child,
.simcal-default-calendar li.simcal-attendee:last-child {
	margin-bottom: 0;
}
.simcal-default-calendar li.simcal-attachment small,
.simcal-default-calendar li.simcal-attendee small {
	opacity: 0.9;
	text-transform: lowercase;
}

.simcal-default-calendar li.simcal-attachment a,
.simcal-default-calendar li.simcal-attendee a,
.simcal-default-calendar .simcal-organizer a {
	border-bottom: 0;
	text-decoration: none;
}

.simcal-default-calendar li.simcal-attachment img,
.simcal-default-calendar li.simcal-attendee img,
.simcal-default-calendar .simcal-organizer img {
	display: inline-block;
	margin: 0 4px;
	max-height: 24px;
	max-width: 24px;
}

.simcal-default-calendar .simcal-tooltip-content {
	font-size: 1.2em;
	line-height: 1.4;
	padding: 5px;
}

.simcal-default-calendar .simcal-ajax-loader {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.simcal-default-calendar .simcal-ajax-loader > i {
	font-size: 48px;
	left: 50%;
	line-height: 1;
	margin: -36px 0 0 -36px;
	position: absolute;
	top: 50%;
}
.simcal-default-calendar .simcal-ajax-loader.simcal-spinner-top > i {
	top: 20%;
}
.simcal-default-calendar .simcal-ajax-loader.simcal-spinner-bottom > i {
	bottom: 20%;
	top: auto;
}

.simcal-default-calendar-light .simcal-nav-button {
	color: rgba(0, 0, 0, 0.6);
}
.simcal-default-calendar-light .simcal-nav-button:disabled {
	color: rgba(255, 255, 255, 0.9) !important;
}
.simcal-default-calendar-light .simcal-nav-button:disabled:hover {
	color: rgba(255, 255, 255, 0.9) !important;
}
.simcal-default-calendar-light .simcal-nav-button:focus,
.simcal-default-calendar-light .simcal-nav-button:hover {
	color: rgba(0, 0, 0, 0.9);
}

.simcal-default-calendar-light .simcal-events-toggle {
	color: rgba(0, 0, 0, 0.6);
}
.simcal-default-calendar-light .simcal-events-toggle:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: white;
}

.simcal-default-calendar-light .simcal-ajax-loader {
	background-color: rgba(0, 0, 0, 0.1);
}
.simcal-default-calendar-light .simcal-ajax-loader > i {
	color: rgba(0, 0, 0, 0.3);
}

.simcal-default-calendar-dark .simcal-nav-button {
	color: rgba(255, 255, 255, 0.6);
}
.simcal-default-calendar-dark .simcal-nav-button:disabled {
	color: rgba(0, 0, 0, 0.9) !important;
}
.simcal-default-calendar-dark .simcal-nav-button:disabled:hover {
	color: rgba(0, 0, 0, 0.9) !important;
}
.simcal-default-calendar-dark .simcal-nav-button:focus,
.simcal-default-calendar-dark .simcal-nav-button:hover {
	color: rgba(255, 255, 255, 0.9);
}

.simcal-default-calendar-dark .simcal-events-toggle {
	color: rgba(255, 255, 255, 0.6);
}
.simcal-default-calendar-dark .simcal-events-toggle:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: black;
}

.simcal-default-calendar-dark .simcal-ajax-loader {
	background-color: rgba(255, 255, 255, 0.1);
}
.simcal-default-calendar-dark .simcal-ajax-loader > i {
	color: rgba(255, 255, 255, 0.3);
}

.simcal-default-calendar-grid > table {
	table-layout: fixed;
	width: 100%;
}
.simcal-default-calendar-grid > table thead th,
.simcal-default-calendar-grid > table tbody td {
	text-align: center;
	vertical-align: top;
}
.simcal-default-calendar-grid > table tbody td {
	padding: 0 !important;
}

.simcal-default-calendar-grid .simcal-calendar-head .simcal-nav {
	padding: 10px 0;
	vertical-align: middle;
}
.simcal-default-calendar-grid .simcal-calendar-head .simcal-nav.simcal-prev-wrapper,
.simcal-default-calendar-grid .simcal-calendar-head .simcal-nav.simcal-current {
	border-right: 0;
}

.simcal-default-calendar-grid .simcal-day > div {
	box-sizing: content-box;
	display: block;
	height: 100%;
}

.simcal-default-calendar-grid .simcal-day-void {
	border-width: 0 0 1px 0;
	height: 100%;
	min-height: 32px;
}
.simcal-default-calendar-grid .simcal-day-void.simcal-day-void-last {
	border-right-width: 1px;
}

.simcal-default-calendar-grid .simcal-day-number {
	display: block;
	line-height: 1;
	padding: 2px 4px 3px;
	vertical-align: middle;
}

.simcal-default-calendar-grid .simcal-no-events {
	display: block;
	min-height: 32px;
}

.simcal-default-calendar-grid.simcal-default-calendar-light .simcal-day:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.simcal-default-calendar-grid.simcal-default-calendar-light .simcal-day-void {
	background-color: rgba(0, 0, 0, 0.04);
}
.simcal-default-calendar-grid.simcal-default-calendar-light .simcal-day-void:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.simcal-default-calendar-grid.simcal-default-calendar-light .simcal-day-number {
	background: rgba(0, 0, 0, 0.1);
}

.simcal-default-calendar-grid.simcal-default-calendar-dark .simcal-day:hover {
	background-color: rgba(255, 255, 255, 0.18);
}

.simcal-default-calendar-grid.simcal-default-calendar-dark .simcal-day-void {
	background-color: rgba(255, 255, 255, 0.05);
}
.simcal-default-calendar-grid.simcal-default-calendar-dark .simcal-day-void:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

.simcal-default-calendar-grid.simcal-default-calendar-dark .simcal-day-number {
	background: rgba(255, 255, 255, 0.1);
}

.simcal-default-calendar-grid .simcal-events {
	font-size: 0.68em;
	line-height: 1.4;
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;
}
.simcal-default-calendar-grid .simcal-events > .simcal-event {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	cursor: pointer;
	list-style: none;
	margin: 0 0 2px 0;
	padding: 4px;
}
.simcal-default-calendar-grid .simcal-events > .simcal-event:hover {
	text-decoration: underline;
}
.simcal-default-calendar-grid .simcal-events > .simcal-event:last-child {
	border-bottom: 0;
	margin-bottom: 0;
}

.simcal-default-calendar-grid.simcal-default-calendar-light .simcal-event {
	border-bottom-color: rgba(0, 0, 0, 0.1);
}

.simcal-default-calendar-grid.simcal-default-calendar-dark .simcal-event {
	border-bottom-color: rgba(255, 255, 255, 0.1);
}

.simcal-default-calendar-grid .simcal-events-dots {
	cursor: pointer;
	display: block;
	line-height: 0.7;
	margin: 3px 0;
	text-align: center;
}

.simcal-default-calendar-grid .simcal-events-toggle {
	background: transparent;
	display: block;
	font-size: 10px;
	padding: 2px 0;
}

.simcal-event-bubble {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.simcal-event-bubble .simcal-event-details p,
.simcal-event-bubble .simcal-event-details .simcal-event-description {
	margin: 0 0 1em;
}

.print-calendar-button {
	margin-top: 20px;
}

@media print {
	.simcal-print-calendar main,
	.simcal-print-calendar #header,
	.simcal-print-calendar #footer,
	.simcal-print-calendar #nav,
	.simcal-print-calendar header,
	.simcal-print-calendar footer,
	.simcal-print-calendar .site-content,
	.simcal-print-calendar .print-calendar-button,
	.print-calendar-button,
	.simcal-print-calendar .simcal-nav .simcal-prev,
	.simcal-print-calendar .simcal-nav .simcal-next,
	.simcal-print-calendar .fc-button-group,
	.simcal-print-calendar .fc-toolbar-chunk .fc-today-button,
	.simcal-print-calendar .fc-dayGridMonth-button,
	.simcal-print-calendar .fc-timeGridWeek-button,
	.simcal-print-calendar .fc-timeGridDay-button {
		display: none !important;
	}
}
